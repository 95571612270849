import { name } from "ejs";
import { title } from "process";

export const es ={
  "title.hello": "Hola de ejemplo desde i18n",
  "nav": {
    "courses": "Cursos",
    "method": "Método Liceo",
    "plans": "Planes",
    "blog": "Blog",
    "login": "Entrar",
    "free_trial": "PRUEBA GRATIS"
  },
  "liceo_play":{
    "title_1":"Demuestra que eres un experto",
    "title_2":"Juega, practica y consolida tus conocimientos de forma divertida.",
    "soon":"Disponible en",
    "wrap_1": {
      "title_1":"Desafía a",
      "title_2":"",
      "desc":""
    }
  },
  "footer":{
    "title":"Impulsando la nueva farmacia",
    "sub_title":"Formación de vanguardia para potenciar tus habilidades",
    "t_pages":"Páginas",
    "t_courses":"Cursos",
    "t_contact":"Contacto",
    "t_legal":"Legales",
    "t_method":"Método Liceo",
    "t_profile":"Perfil",
    "t_test":"Probar gratis",
    "t_estats":"Campus",
    "t_liceo_play":"Liceo Play",
    "t_infiltrada":"La Infiltrada",
    "t_protocolos":"Protocolos de consejo",
    "t_suscpription":"Mis suscripciones",
    "email":"info@liceodefarmacia.com",
    "copy":"Copyright © 2024. Portero Formación, S.L.",
    "privacy":"Política de privacidad",
    "cookies":"Política de cookies",
    "legal":"Aviso legal",
    "faqs": "Preguntas frecuentes",
  },
  "home": {
    "title": "Impulsando la nueva farmacia",
    "sub_title": "Formación de vanguardia para potenciar tus habilidades",
    "block-ge": "Ge",
    "block-word-ge": "Gestión",
    "block-ex": "Ex",
    "block-word-ex": "Experiencia",
    "block-fo": "Fo",
    "block-word-fo": "Formación",
    "adv-title-1": "Aprende con expertos",
    "adv-desc-1": "Contenido creado por profesionales independientes del sector.",
    "adv-title-2": "Todo incluido",
    "adv-desc-2": "Acceso a todos los cursos del catálogo en plan profesional y farmacia.",
    "adv-title-3": "Formación práctica",
    "adv-desc-3": "Aprende con nuestras escenas de mostrador.",
    "number-of-courses": "+ de 150 cursos",
    "liceo-promise-1": "Compromiso Liceo:",
    "liceo-promise-2": "Siempre creando nuevos contenidos.",
    "farmacias-razones-title": "¿Por qué nos eligen las farmacias?",
    "farmacias-razones-1": "Para estar actualizados en el mostrador.",
    "farmacias-razones-2": "Para hacer crecer su negocio.",
    "farmacias-razones-3": "Para formar y motivar a los equipos.",
    "farmacias-razones-btn": "PRUEBA GRATIS",
    "farmacias-metodo-title": "Método Liceo",
    "farmacias-metodo-1": "Píldoras de video de unos 5 minutos. ",
    "farmacias-metodo-2": "Centrados en la formación práctica. ",
    "farmacias-metodo-3": "Contenido creado por profesionales.",
    "farmacias-metodo-btn": "LEER MÁS",
    "opiniones-title": "Lo que opinan nuestros clientes",
    "opiniones-user-1-name": "Miguel Ángel Ruiz",
    "opiniones-user-1-company": "Farmacia MA Ruiz Collado",
    "opiniones-user-1-text": "\"Formar continuamente  al equipo es clave para nosotros, y nos gusta el enfoque del programa de Liceo, buscando dar formación 360º práctica y de gestión\"",
    "opiniones-user-2-name": "Rafael Montañés",
    "opiniones-user-2-company": "Farmacia CC Área Sur",
    "opiniones-user-2-text": "\"Liceo nos resuelve varios problemas de una tacada: nos nutren de formaciones tremendamente efectivas en una plataforma donde, además, podemos subir nuestros propios contenidos\"",
    "opiniones-user-3-name": "Mónica Piñol",
    "opiniones-user-3-company": "Farmacia La Remèi",
    "opiniones-user-3-text": "\"Magnífica plataforma para mantener una buena formación continuada. Para formarse de forma amena y poder usar los contenidos en el día a día de la farmacia.\"",
    "text-confia":"Confían en nosotros",
    "pre-text":"Promovido por",
    "legal":"PORTERO FORMACIÓN, S.L, ha sido beneficiaria del Fondo Europeo de Desarrollo Regional cuyo objetivo es promover el desarrollo tecnológico, la innovación y una investigación de calidad; garantizar un mejor uso de las tecnologías de la información y conseguir un tejido empresarial más competitivo y gracias a “Implantación de Sistemáticas y metodologías de Gestión”, que ha mejorado la competitividad de su negocio. Esta acción ha tenido lugar durante el ejercicio 2022. Para ello ha contado con el apoyo del Programa Innocámaras de la Cámara de Comercio Industria, Servicios y Navegación de Sevilla.Una manera de hacer Europa"
  },
  "ml": {
    "title": "Los pilares del método",
    "sub_title": "Creado por farmacéuticos con años de experiencia gestionando boticas, este método busca mejorar y hacer crecer las farmacias centrándose en las personas, el alma de este negocio.",
    "card_me_Letters": "Me",
    "card_me_title": "Mejorar la rentabilidad",
    "card_me_text": "Contar con un equipo bien preparado se traduce en más satisfacción en las personas que acuden a la botica, más uso de nuestros servicios y al final, en una mayor rentabilidad.",
    "card_fo_Letters": "Fo",
    "card_fo_title": "Formación práctica",
    "card_fo_text": "El mostrador exige conocimientos y aptitudes más allá de la mera teoría. Nuestra formación, aunque se base en la parte teórica, siempre tiene un enfoque clave en la parte práctica. ",
    "card_pr_Letters": "Pr",
    "card_pr_title": "Profesionales en activo",
    "card_pr_text": "Todas nuestras formaciones están creadas por profesionales en activo que conocen de primera mano el tema que imparten y que trasladan su experiencia práctica.",
    "farmacias_title": "Validado por farmacias reales",
    "farmacias_text": "El método Liceo ya es utilizado por más de 800 farmacias que han visto crecer su rentabilidad y han fidelizado a las personas que acuden a su botica.",
    "historia_title": "Érase una vez una botica…",
    "historia_text_1": "El germen de Liceo ",
    "historia_text_2": "nace en una farmacia de Sevilla",
    "historia_text_3": ". Motivados por el afán de atender mejor a las personas que acudían a su farmacia. ",    
    "historia_text_4": " Inmaculada Portero organizó ",    
    "historia_text_5": "Formaciones continuas para su equipo",    
    "historia_text_6": ". Al poco tiempo de instaurar esta práctica la farmacia empezó a ver aumentar su rentabilidad. Poco a poco otros farmacéuticos empezaron a solicitar a Inmaculada para formar a sus equipos, y el boca a boca hizo que cada vez más farmacias mostrasen su interés.",    
    "historia_text_7": " Así nace Liceo de Farmacia",    
    "historia_text_8": " . Con la intención de llevar ese conocimiento a todas las farmacias, haciendo mejores boticas para las personas, los equipos y los titulares.  ",    
    "metodo_titular_title": "Un equipo formado y motivado",
    "metodo_titular_text": "Formación continua con material de calidad. El mayor catálogo (más de 130 cursos) para que cada empleado se especialice en lo que necesitas. Nuevas formaciones todos los meses. Gamificación del aprendizaje, que ayuda a mantener la motivación. ¿Tienes material formativo propio? Súbelo al espacio privado Liceo de tu farmacia desde el que podrás seguir y medir el desempeño sin esfuerzo.",
    "metodo_titular_title2": "El mejor contenido en gestión",
    "metodo_titular_text2": "No sólo del mostrador vive la farmacia. Abordamos la gestión de tu farmacia como negocio mediante nuestros cursos de Gestión.",
    "metodo_titular_title3": "App protocolos de consejo",
    "metodo_titular_text3": "Instaura los protocolos de consejo en tu farmacia y aumenta las ventas de los productos que deseas, a la vez que fidelizas a las personas con los mejores consejos, trabajados en equipo.",
    "metodo_titular_question": "¿Qué me aporta el Método Liceo?",
    "metodo_profesional_title": "Toma las riendas de tu carrera",
    "metodo_profesional_text1": "Los profesionales Top en farmacia, aquellos que entienden el negocio tan bien como lo entiende el titular, y que saben cómo desarrollarlo, son los que los que todos quieren atraer y retener. Por eso, tanto si quieres que se te rifen, como si quieres tener tu propia farmacia en el futuro, formarte y ser mejor cada día es esencial. Con Liceo, te formarás en la mejor práctica de mostrador y en la gestión de la farmacia, y destacarás inevitablemente sobre los demás.",
    "metodo_profesional_text3": "1 cursos nuevo cada semana",
    "metodo_profesional_text4": "Píldoras de vídeo en 5 min, contenido práctico y escenas de mostrador.",
    "metodo_profesional_text5": "Accesible desde cualquier dispositivo.",
    "metodo_profesional_text6": "Contenido actualizado.",
    "metodo_profesional_title2": "App protocolos de consejo",
    "metodo_profesional_text2": "Instaura los protocolos de consejo en tu farmacia y aumenta las ventas de los productos que deseas, a la vez que fidelizas a las personas con los mejores consejos, trabajados en equipo.",
    "metodo_estudiante_title_1": "Hazte valioso para los titulares",
    "metodo_estudiante_text_1-1": "Las dos mayores quejas del titular sobre sus empleados menos expertos son el tiempo que tardan en adquirir los conocimientos básicos del día a día de la farmacia y su falta de conocimiento práctico en las categorías de mayor prevalencia en sus farmacias. Con Liceo, te formarás en la práctica y las habilidades que están esperando encontrar cuando buscan candidatos.",
    "metodo_estudiante_text_1-2": "App La Infiltrada",
    "metodo_estudiante_text_1-3": "¿Sabes muchos nombres de principios activos pero no sus nombres comerciales? A todos les pasa en sus primeros meses. Con nuestro juego “La Infiltrada” los aprenderás mientras juegas y compites con otros.",
    "metodo_estudiante_text_1-4": "Aprender tiene que ser divertido",
    "metodo_estudiante_text_1-5": "Buscamos que te apetezca formarte un poco cada día, y para eso, te lo ponemos fácil: cursos amenos e interesantes, enfocados a la práctica y uso de juegos para reforzar lo que aprendes. Además, puedes acceder a Liceo desde el dispositivo que quieras.",
    "metodo_estudiante_title_2": "App protocolos de consejo",
    "metodo_estudiante_text_2": "Instaura los protocolos de consejo en tu farmacia y aumenta las ventas de los productos que deseas, a la vez que fidelizas a las personas con los mejores consejos, trabajados en equipo.",
    "metodo_btn_prueba_gratis": "PRUEBA GRATIS",
    "metodo_contacta": "SOLICITAR LLAMADA",
    "metodo_btn_plan_farma": "Ver planes equipo farmacia",
    "profesionales_title": "Profesionales independientes",
    "profesionales_text": "Todo el contenido de Liceo está realizado por profesionales del sector farmacia, independientes de cualquier empresa o marca. Garantizando que los contenidos de Liceo están basados en necesidades reales de la botica y no en intereses externos.",
    "metodo_btn_plan_profesional": "MÁS INFORMACIÓN",
    "metodo_btn_plan_estudiante": "Ver plan estudiante de farmacia",
    "metodo_im_student":"Soy un estudiante",
    "metodo_im_titular":"Soy un titular",
    "metodo_im_profesional":"Soy un profesional"
  },
  "dashboard":{
    "your_progress": "Tu progreso:",
    "level": "Nivel",
    "dedication": "Dedicación",
    "configuration": "Administrar itinerarios",
    "it_title": "Mis itinerarios",
    "exp_it_title": "Curso Liceo Expert",
    "enrollment": "Matriculados",
    "completed": "Completados",
    "progress": "Progreso",
    "course_title": "Mis cursos",
    "courses": "cursos",
    "clean_filters": "Limpiar filtros",
    "search_result": "Resultados de búsqueda:",
    "last_achievement": "Último logro desbloqueado",
    "progress_of": "Progreso de",
    "filters": "Filtros",
    "no_it_msg": "Estamos trabajando en nuevos itinerarios. Disculpa las molestias. ¡Explora nuestros cursos individuales mientras tanto!",
    "see_detail": "Ver detalle"
  },
  "itinerary":{
    "complete_courses": "cursos completados",
    "go_to_course": "Ir al curso",
    "go": "Ir",
    "show_courses": "Ver cursos",
    "show_details": "Ver detalle"
  },
  "info_btn":{
    "sponsor_by": "Patrocinado por",
    "desc": "DESCRIPCIÓN"
  },
  "courses": {
    "title": "Cursos de Liceo de Farmacia",
    "description_1": "Este es nuestro catálogo de cursos al que tendrás acceso con los planes de Liceo.",
    "description_2": "Cursos de diversas categorías como Dermo, Gestión, Productos, Patologías, entre otros…",
    "description_3": "Todos creados por profesionales del sector y diseñados para que los puedas consumir en pequeñas píldoras de video.",
    "featured_courses_title": "Cursos destacados",
    "search_text": "Resultados de la búsqueda ",
    "search_text_no_results": "No hay resultados para ",
    "last_courses_title": "Últimos cursos",
    "avaible_courses_title": "Tu selección de cursos disponibles",
    "filter_text": "Filtrados por:",
    "see_more_btn": "VER MÁS",
    "search_input": "Buscar",
    "error": {
      "not_found": "No hay cursos disponibles en este momento"
    },
    "category":"Categoría: ",
    "placeholderCategory": "Categorías"
  },
  "course": {
    "btn": "VER MÁS",
    "error": {
      "not_found": "La información de este curso no esta disponible en este momento, por favor inténtalo de nuevo más tarde."
    }
  },
  "login": {
    "title": "Entrar",
    "register_1": "¿No tienes cuenta?",
    "register_2": "Regístrate gratis",
    "complete_1": "Entra a tu cuenta para completar tu compra.",
    "complete_2": "para completar tu compra.",
    "btn": "INICIAR SESIÓN",
    "forget": "He olvidado mi contraseña",
    "input_email_message_error": "Datos incorrectos, revise los campos",
    "input_mail": "Email",
    "input_password": "Contraseña",
    "input_email_message_empty_error": "Este campo es obligatorio",
    "input_email_message_type_error": "Este campo debe ser de tipo email",
    "input_password_message_empty_error": "Este campo es obligatorio"
  },
  "purchase": {
    "complete": "Método de pago",
    "encryption": "Cifrado de extremo a extremo",
    "iva": " *IVA inluido",
    "month_pay_details_1": "Usaremos este método de pago para renovar tu suscripción cada mes.",
    "month_pay_details_2": "Ahora se te cobrarán",
    "month_pay_details_3": "y el siguiente pago será dentro de un mes.",
    "year_pay_details_1": "Usaremos este método de pago para renovar tu suscripción cada año.",
    "year_pay_details_2": "Ahora se te cobrarán",
    "year_pay_details_3": "y el siguiente pago será dentro de un año.",
    "month_cancel_details_1": "Hoy se te cobrará un pago de ",
    "month_cancel_details_2": "y se renovará automáticamente en este método de pago cada mes. Puedes cancelar cuando quieras en la sección ",
    "month_cancel_details_3": "Mis suscripciones. ",
    "month_cancel_details_4": "También puedes escribirnos al email ",
    "month_cancel_details_5": "hola@liceodefarmacia.com.",
    "year_cancel_details_1": "Hoy se te cobrará un pago de ",
    "year_cancel_details_2": "y se renovará automáticamente en este método de pago cada año. Puedes cancelar cuando quieras en la sección ",
    "year_cancel_details_3": "Mis suscripciones. ",
    "year_cancel_details_4": "También puedes escribirnos al email ",
    "year_cancel_details_5": "hola@liceodefarmacia.com.",
    "title": "Total: ",
    "info": "Suscripción con tarjeta",
    "input_card": "Nº de tarjeta;",
    "free_trial_btn": "PROBAR GRATIS",
    "input_date": "mm/aa",
    "input_cvv": "CVV",
    "btn": "SUSCRIBIRME",
    "monthly": "(0/MES)",
    "error_screen": {
      "title": "¡Ups! Lo sentimos",
      "text_1": "No se ha podido procesar el pago",
      "text_2": "Inténtalo de nuevo más tarde o ponte en contacto con nosotros en",
      "text_3": "  soporte@liceodefarmacia.com",
    }
  },
  "already_have_a_plan": {
    "title": "¡Ya tienes una suscripción activa!",
    "desc_1": "Recuerda que puedes gestionar tu suscripción en",
    "desc_2": " Mis suscripciones",
    "price_text_month": "Pago mensual",
    "price_text_year": "Pago anual",
    "price_text_monthly": "mes",
    "price_text_yearly": "año",
    "btn": "IR AL CAMPUS"
  },
  "demo_already_used": {
    "title_1": "¡Ups!",
    "title_2": "Ya has disfrutado de la prueba gratis de 5 días",
    "desc": "¿Listo para contratar tu plan?",
    "btn": "VER PLANES"
  },
  "free_trial": {
    "complete": "Prueba 5 días gratis",
    "summary": {
      "pay-legal-1": "Una vez finalizado tu periodo de prueba se realizará el primer pago de: ",
      "pay-legal-2": "La renovación de tu suscripción se realizará de forma automática.",
      "pay-legal-3": "Recuerda que puedes cancelarla en cualquier momento.",
      "pay-please":"Por favor, revisa los datos de tu tarjeta y vuelve a intentarlo"
    },
    "back":"< volver a liceo",
    "title": "Prueba gratis 5 días",
    "step": "Paso 1/2",
    "step_2": "Paso 2/2",
    "info": "Regístrate",
    "info_1": "para activar tu prueba gratis",
    "info_2": "¿Ya tienes cuenta?",
    "info_3": "Inicia sesión",
    "input_name": "Nombre",
    "input_last_name": "Apellidos",
    "input_email": "Email",
    "input_user_name": "Nombre de usuario",
    "input_password": "Contraseña",
    "input_nameSpace": "Nombre de farmacia",
    "input_type": "Soy profesional",
    "try_btn": "PRUEBA GRATIS",
    "register": {
      "error_msg": {
        "telephone_invalid": "Este campo debe ser de tipo teléfono (9 dígitos)",
        "input_name": "Este campo es obligatorio",
        "input_nameSpace": "Este campo es obligatorio",
        "input_nameSpace_exist": "Este nombre de farmacia ya existe, prueba otro",
        "input_last_name": "Este campo es obligatorio",
        "input_email": "Este campo es obligatorio",
        "email_invalid": "Este campo debe ser de tipo email",
        "input_is_not_email": "Este debe ser de tipo email",
        "mail_not_found": "Este correo electrónico no está registrado",
        "input_user_name": "Este campo es obligatorio",
        "input_password": "Este campo es obligatorio",
        "input_password_min": "La contraseña debe tener al menos 8 caracteres",
        "incorrect_password": "Contraseña incorrecta"
      }
    },
    "login": {
      "title": "Inicia sesión",
      "step": "Paso",
      "info": "¿Aún no tienes cuenta?",
      "info_2": "Regístrate",
      "try_btn": "Este campo es obligatorio"
    },
    "return_btn": "< Volver a Liceo",
    "demo_already_used": {
      "text_1": "Tu prueba gratis ha finalizado",
      "text_2": "Mejora y sigue  aprendiendo con nuestros cursos por suscripción",
      "btn": "SUSCRIBIRME"
    },
    "selection":{
      "return_btn": "Volver",
      "title_1": "Selecciona",
      "title_2": "un plan",
      "title_3": "para ti",
      "info_text_1": "Confían en nosotros desde",
      "info_text_2": "profesionales",
      "info_text_3": "a",
      "info_text_4": "grandes grupos farmacéuticos",
      "info_card_1": "Accede a todos los cursos",
      "info_card_2": "Disfruta de la prueba gratis",
      "info_card_3": "Cancela cuando quieras",
    },
    "contact":{
      "title": "Consigue tu prueba gratis",
      "title_buy": "¿Te llamamos?",
      "desc": "Un miembro de nuestro equipo se pondrá en contacto contigo para explicarte cómo sacarle el máximo partido a Liceo de farmacia.",
      "desc_link": "",
      "check_terms_1": "He leído y acepto los",
      "check_terms_2": "Términos y Condiciones",
      "check_terms_3": "y la",
      "check_terms_4": "Política de Privacidad.",
      "subscribe_check": "Acepto recibir comunicaciones personalizadas.",
      "submit": "QUIERO QUE ME CONTACTEN",
      "form_placeholders":{
        "name": "Nombre",
        "lastName": "Apellidos",
        "telephone": "Nº de teléfono",
        "email": "Email",
        "pharmaName": "Nombre de farmacia"
      },
      "form_error_msg":{
        "name_error_msg": "Este campo es obligatorio",
        "lastName_error_msg": "Este campo es obligatorio",
        "telephone_error_msg": "Este campo es obligatorio",
        "email_error_msg": "Este campo es obligatorio",
        "pharmaName_error_msg": "Este campo es obligatorio"
      },
      "contact-success":{
        "title": "¡Recibido!",
        "text_1": "pronto nos pondremos en contacto contigo.",
        "text_2": "Mientras esperas, hemos dejado en tu correo información de bienvenida sobre las herramientas de Liceo.",
      }
    }
  },
  "register": {
    "title": "Regístrate",
    "info_1": "Regístrate",
    "info_2": "para completar tu compra.",
    "login_1": "¿ya tienes cuenta?",
    "login_2": "Entra",
    "input_mail": "Email",
    "input_username": "Nombre de usuario",
    "input_password": "Contraseña",
    "btn": "CREAR CUENTA",
    "mail_used": "Email ya en uso, utiliza otro",
    "username_used": "Nick ya en uso, utiliza otro",
    "mail_invalid": "Email no válido",
    "password_invalid": "La contraseña debe tener al menos 8 caracteres",
    "error": "Algo salió mal, intentalo de nuevo"
  },
  "course_detail": {
    "course_content": {
      "title": "Contenido del curso",
      "category": "Categoría: ",
      "target": "Dirigido a: ",
      "sub_info": "Este curso forma parte del catálogo de Liceo de Farmacia por suscripción",
      "btn": "VER PLANES"
    },
    "goals": {
      "title": "Objetivos"
    },
    "content": {
      "title": "Contenido"
    },
    "related_courses": {
      "title": "Cursos relacionados",
      "see_more_btn": "VER MÁS"
    },
    "plans": {
      "title": "Elige tu plan",
      "link": "Ver planes",
      "option_1": "Individual",
      "option_2": "Farmacia",
      "timing_1": "Mensual",
      "timing_2": "Anual",
      "timing_offer": "-> Ahorra un 20%"
      
    },
    "courses": {
      "student": "Cursos incluidos"
    }
  },
  "opinion": {
    "btn": "VER CASO"
  },
  "plans_component": {
    "offer": "Ahorra un 20%",
    "iva": "IVA no incluido",
    "money_month":"€/mes",
    "coin":"€",
    "text_prev":"Un pago único de",
    "know_more":"Ver detalles",
    "hold_update":"Aprende con los mejores expertos del sector y conviértete en el profesional que toda farmacia necesita.",
    "student":"Consigue tu primer trabajo con la seguridad de un profesional.",
    "titular_1":"El plan para farmacias de hasta ",
    "titular_2":" integrantes. Lleva tu botica al siguiente nivel.",
    "get_job":"Consigue el trabajo que deseas en farmacia con 20 cursos impartidos por profesionales en activo.",
    "buy":"COMPRAR",
    "buy_now":"COMPRAR AHORA",
    "test_free":"PROBAR GRATIS",
    "more_info":"MÁS INFO"
  },
  "plans_sec": {
    "periods":{
      "monthly": "MENSUAL",
      "annual": "ANUAL"
    },
    "plans": {
      "title": "Elige tu plan y potencia tus habilidades"
    }
  },
  "cart": {
    "btn": "IR AL CARRITO",
    "empty_btn": "VACIAR CARRITO",
    "summary": {
      "selected": "Plan seleccionado:",
      "month_pay_1": "Pago mensual",
      "month_pay_2": "€/mes",
      "year_pay_1": "Pago anual",
      "year_pay_2": "€/año",
      "change_plan": "CAMBIAR PLAN",
      "step": "PASO 1/2",
      "step_2": "PASO 2/2",
      "free_trial_price": "0€ (Prueba gratis 5 días)",
      "title": "Tu compra:",
      "include-1": "Incluye",
      "include-2": "todo el catálogo de cursos de Liceo",
      "description-1": "Accede a todo el catálogo de Liceo",
      "description-2": "Más de 150 cursos",
      "description-3": "Un nuevo curso cada semana",
      "description-4": "Contenido creado por profesionales",
      "description-5": "Formación práctica",
      "description-6": "Píldoras de video de 5 menos de minutos",
      "pay-1": "Pago",
      "pay-2": "100% seguro",
      "back": "< Volver a liceo",
      "register-0": "Crea tu cuenta",
      "login-0": "Iniciar sesión",
      "register-1": "Para completar tu compra.",
      "register-2": "¿Ya tienes cuenta?",
      "login-2": "¿Aún no tienes cuenta?",
      "register-3": "Entrar",
      "login-3": "Regístrate",
      "btn-buy": "REGISTRARME",
      "pay-legal-1": "*Se te cargarán",
      "pay-legal-2": "La renovación de tu suscripción se realizará de forma automática.",
      "pay-legal-3": "Recuerda que puedes cancelarla en cualquier momento.",
      "pay-total": "Total"
    },
    "order_courses":{
      "block": "Bloquear",
      "unlock": "Desbloquear",
      "avaible_date": "Curso disponible el"
    },
    "empty": {
      "title": "Carrito vacío",
      "txt-1": "Aún no has añadido nada al carrito",
      "txt-2": "Descubre nuestro",
      "txt-3": "catálogo de cursos",
      "btn": "VER PLANES",
      "txt-4": "Regístrate para activar tu",
      "txt-5": "prueba gratis",
      "btn-2": "PRUEBA GRATIS"
    },
    "ok": {
      "title": "¡Enhorabuena!",
      "text-1": "Ya tienes acceso a tu",
      "text-2": "Empieza ya con uno de",
      "text-3": "nuestros cursos recomendados",
      "btn": "IR AL CAMPUS",
      "title-1": "¡Hola, ",
      "title-1-2": "!",
      "title-2": "Te damos la bienvenida a Liceo",
      "formule": "Nuestra fórmula"
    },
    "ko": {
      "title": "¡Ups, algo ha ido mal!",
      "text-1": "Tu pago se ha realizado correctamente",
      "text-2": ", pero algo ha ido mal en la asignación del curso. Por favor escríbenos a:",
      "email": "info@liceodefarmacia.com"
    }
  },
  "free-trial": {
    "summary": {
      "title": "Suscripción:",
      "details": "Sólo usaremos tu método de pago cuando se acabe tu prueba gratis. Puedes cancelar tu prueba gratis sin ningún coste antes de que acaben los 5 días.",
      "cancel_detail_1": "Después de los 5 días se te cobrarán",
      "cancel_detail_2": "Puedes cancelar cuando quieras en la sección",
      "cancel_detail_3": "Mis suscripciones.",
      "cancel_detail_4": "También puedes escribirnos al email",
      "cancel_detail_5": "hola@liceodefarmacia.com."
    }
  },
  "profile_login": {
    "register_question": "¿No tienes cuenta?",
    "register_1": "Regístrate",
    "register_2": "para activar tu prueba gratis",
    "register_btn": "PRUEBA GRATIS",
    "forgot_pass": "He olvidado mi contraseña",
    "login_title": "Entrar",
    "login_description_0": "Entra ",
    "login_description_1": " y descubre las ventajas de nuestro",
    "login_description_2": "catálogo de cursos",
    "input_email": "Email",
    "input_password": "Contraseña",
    "login_btn": "INICIAR SESIÓN"
  },
  "my_profile": {
    "close_session": "Cerrar sesión",
    "monthly": "/mes",
    "year": "/año",
    "annual_price": "Pago anual",
    "monthly_price": "Pago mensual",
    "annual": "Anual * prepago ",
    "text_1":"*El siguiente pago de",
    "canceled": "Cancelada",
    "canceled_text_1": "Has cancelado tu suscripción. No se renovará automáticamente el ",
    "canceled_text_2": "y no se te cobrará nada. Sigues teniendo acceso a Liceo hasta esa fecha. Recuerda que tienes disponibles todos nuestros planes para suscribirte.",
    "text_1_2":"€, se efectuará el",
    "text_2":"*Tu suscripción se ha cancelado correctamente. Se hará efectiva el día",
    "text_2_2":". Hasta entonces, puedes seguir disfrutando de liceo.",
    "text_3":"*El siguiente pago de ",
    "text_3_2":"€, se efectuará el ",
    "text_4":"*Tu prueba gratis se ha cancelado correctamente. Se hará efectiva el día",
    "text_4_2":". Hasta entonces, puedes seguir disfrutando de liceo de forma gratuita. ",
    "current_plan": "PLAN ACTUAL",
    "plans_not_found": "Información no disponible, inténtelo de nuevo o mas tarde.",
    "finish_sub": "CANCELAR SUSCRIPCIÓN",
    "desc_1": "Tu suscripción se renueva el",
    "desc_2": "automáticamente en el método de pago aportado. Puedes cancelar la suscripción cuando quieras.",
    "free_trial_price": "0€ (Prueba gratis 5 días)",
    "no_plans": {
      "title": "No tienes ninguna suscripción activa.",
      "description": "Consulta nuestros planes de suscripción",
      "btn": "VER PLANES"
    },
    "go_classroom": {
      "btn": "IR AL CAMPUS"
    },
    "cancel_modal": {
      "title": "Tu éxito es nuestra meta",
      "desc_0": "Esperamos que hayas obtenido la formación que buscabas y que te hayamos ayudado a alcanzar tus objetivos.",
      "desc_1": "Tendrás acceso hasta el",
      "desc_2": "¡Aprovéchalo al máximo!",
      "desc_3": "Si decides buscar nuevas habilidades en el futuro, aquí estaremos para ayudarte en tu crecimiento profesional.",
      "continue_btn": "SEGUIR EN LICEO",
      "cancel_btn": "CANCELAR MI SUSCRIPCIÓN",
      "cancel_success": "¡Suscripción cancelada con éxito!",
    }
  },
  "plan_detail": {
    "student": {
      "title_1": "PLAN LICEO PARA",
      "title_2_type_1": "EQUIPO DE FARMACIA",
      "title_2_type_2": "PROFESIONAL DE FARMACIA",
      "title_2_type_3": "ESTUDIANTE DE FARMACIA",
      "desc_1_type_1": "Motiva y potencia las habilidades de tu equipo de farmacia",
      "desc_1_type_2": "Potencia tus habilidades con los mejores profesionales",
      "desc_1_type_3": "Consigue el trabajo que deseas en farmacia",
      "desc_2": "Cursos que te ayudarán a conseguir tu objetivo, creados por los titulares que te evaluarán en una entrevista.",
      "btn": "PRUEBA GRATIS",
      "theory_title": "No todo es teoría",
      "theory_desc": "Aprende la parte práctica necesaria para perderle miedo al mostrador y ser capaz de enfrentar cualquier situación en la botica.",
      "interview_title": "Haz una buena entrevista",
      "interview_desc": "Aprender los puntos claves que busca un titular es muy importante a la hora de enfrentarte a una entrevista en farmacia.",
      "theory":{
        "see_more":"VER MÁS",
        "edit":"EDITAR",
        "titulares":{
          "title":"Protocolos de consejo",
          "created_by":"Curso creado e impartido por",
          "author":"Javier Baquero",
          "desc": "Te explicamos cómo implementar los protocolos de consejo.",
          "succes": "Curso asignado con éxito",
          "enabled_courses": "Una parte del equipo ya tiene cursos disponibles.",
          "be_better_1":"Mejora en gestión de tu farmacia",
          "be_better_2":"Mejora de la rentabilidad",
          "title_content":"El mejor contenido en gestión",
          "parraf_content_1":"El mejor contenido en gestión",
          "parraf_content_2":"Aprende a gestionar mejor tu tiempo.",
          "parraf_content_3":"Formaciones diseñadas por titulares de éxito.",
          "parraf_content_4":"Contenido enfocado en mejorar la rentabilidad de la botica."
        },
        "profesionals":{
          "title":"La piel",
          "desc": "Es necesario conocer la piel, es decir, su estructura, sus funciones y las posibles patologías o problemas que afectan a este órgano para poder resolver las múltiples consultas que recibiremos diariamente en la oficina de farmacia.",
          "active_course":"Cursos formativos activos",
          "enabled_courses": "Aquí puedes encontrar tus cursos disponibles.",
          "parraf_content_1":"Contenido para mejora como profesional",
          "parraf_content_2":"Contenido actualizado",
          "parraf_content_3":"Escenas de mostrador",
          "parraf_content_4":"Una buena formación es clave para tu éxito como profesional y el de tu farmacia."
        },
        "students":{
          "title":"La entrevista perfecta",
          "desgined_for":"Curso diseñado para",
          "name":"estudiantes",
          "name_2":"profesionales y estudiantes",
          "desc": "Cuando una farmacia ofrece un puesto de empleo, significa que tiene una serie de necesidades que deben cubrirse. Para optar a ese puesto, no es necesario que seas el candidato perfecto capaz de todo, simplemente basta con ser perfecto para ese trabajo en concreto.",
          "created_for":"formación creada por expertos del sector.",
          "enabled_courses": "Aquí puedes encontrar tus cursos disponibles.",
          "text_1":"Escenas de mostrador",
          "text_2":"Herramientas basadas en la gamificación",
          "parraf_content_1":"Haz una buena entrevista",
          "parraf_content_2":"Aprender los puntos claves que busca un titular es muy importante a la hora de enfrentarte a una entrevista en farmacia.",
          "not_theory":"No todo es teoría",
          "parraf_content_3":"Aprende la parte práctica necesaria para perderle miedo al mostrador y ser capaz de enfrentar cualquier situación en la botica.",
          "example_title":"Antigripales",
          "example_desc":"El objetivo principal de esta píldora es conocer los tratamientos para la gripe, especialmente los medicamentos de indicación farmacéutica, EFP, de forma que podamos adaptar cada tipo de antigripal a las necesidades de cada cliente.",
          "example_parraf_1":"Formación práctica sobre el día a día",
          "example_parraf_2":"Escenas de mostrador",
          "example_parraf_3":"Formación con los mejores profesionales"
        }
      },
      "content": {
        "title": "Con este plan tendrás acceso",
        "theory_title": "Parte teórica",
        "theory_block_te": "Te",
        "theory_block_word_te": "Teoría",
        "theory_desc": "Conceptos básicos y fundamentales sobre los que se sostienen  las bases teóricas de nuestros cursos.",
        "practice_title": "Parte práctica",
        "practice_block_pr": "Pr",
        "practice_block_word_pr": "Práctica",
        "practice_desc": "Recursos y tips prácticos que te ayudarán a ver la importancia de buenas prácticas de farmacia",
        "downloads_title": "Descargables",
        "downloads_block_de": "De",
        "downloads_block_word_de": "Descargables",
        "downloads_desc": "Accederás a contenido descargable que te ayudará a conseguir tu objetivo: trabajar en farmacia",
        "btn": "COMPRAR AHORA",
        "free_trial": "COMENZAR PRUEBA GRATIS"
      },
      "infiltrada": {
        "title": "La infiltrada",
        "subtitle": "DISPONIBLE CON ESTE PLAN",
        "desc_1": "¿Sabes identificar qué marcas contienen los principios activos?",
        "desc_2": "Con este plan tendrás acceso PREMIUM a la app que te ayuda a aprender de forma divertida qué marcas corresponden a cada principio activo.",
        "download_btn": "DESCARGAR APP",
        "info_btn": "SABER MÁS",
        "courses_title": "Cursos de venta única",
        "news": {
          "title": "¿Quieres estar al día de todo nuestro contenido?",
          "desc": "Suscríbete a la Newsletter. Forma parte de Liceo, disfruta de contenido descargable y manténte actualizado con información sobre nuevos cursos para estudiantes.",
          "btn": "SUSCRIBIRME"
        }
      },
      "liceo_play":{
        "title": "Liceo Play",
        "download": "DESCÁRGALA GRATIS"
      },
      "plan_month_title": "PLAN MENSUAL",
      "plan_month_period": "MES",
      "plan_buy_btn": "COMPRAR AHORA",
      "plan_try_btn": "PRUEBA GRATIS",
      "plan_anual_title": "PLAN ANUAL",
      "plan_anual_period": "MES",
      "plan_saving": "Ahorra un 20%",
      "plan_payment": "* Pago único de"
    },
    "profesional": {
      "content": {
        "formation_title": "Parte teórica y práctica",
        "formation_block_fo": "Fo",
        "formation_block_word_fo": "Formación",
        "formation_desc": "Nuestros cursos tratan la parte teórica a la vez que aportan recursos y tips prácticos que te ayudarán a ver la importancia de buenas prácticas en la botica.",
        "personalized_title": "Elige tu camino formativo",
        "personalized_block_pe": "Pe",
        "personalized_block_word_pe": "Personalizada",
        "personalized_desc": "Podrás elegir entre los más de 150 cursos del catálogo de Liceo o seguir los itinerarios formativos. Todos los meses te recomendaremos un curso acorde a la época del año.",
        "progresive_title": "Mide tu progreso y avanza",
        "progresive_block_pr": "Pr",
        "progresive_block_word_pr": "Progresiva",
        "progresive_desc": "Dentro del aula podrás ver un panel de estadísticas con tus puntos, cursos completados, logros obtenidos y cómo llevas los itinerarios formativos que hayas comenzado."
      }
    },
    "titular": {
      "content": {
        "formation_title": "Gestión",
        "formation_block_ge": "Ge",
        "formation_block_word_ge": "Gestión",
        "formation_desc": "La principal preocupación de un titular, sin duda es la gestión. Refuerza esta área con contenido generado por titulares de éxito.",
        "personalized_title": "Finanzas",
        "personalized_block_fi": "Fi",
        "personalized_block_word_fi": "Finanzas",
        "personalized_desc": "La botica es un negocio, y como tal necesita de un manejo correcto de las finanzas. Aprende todo lo necesario de la mano de expertos.",
        "progresive_title": "Power farmacia",
        "progresive_block_pf": "Pf",
        "progresive_block_word_pf": "Power Farmacia",
        "progresive_desc": "Por ser socio de cualquiera de los planes anuales podrás acceder con un descuento del 50% al master para titulares, ver más."
      }
    },
    "content_plan":{
      "this_plan":"Con este plan",
      "acces":"tendrás acceso"
    },
    "opinions":{
      "title":"Lo que opinan otros estudiantes",
      "name_1":"María Calleja del Valle",
      "name_1_prof":"Estudiante Farmacia",
      "name_1_text":"Tanto los cursos de Liceo como la app de la infiltrada me encantan! Los recomiendo sin duda para todos los estudiantes. No sólo es teoría, está muy enfocado en la parte práctica y eso es muy útil. ",
      "name_2":"Pedro Bosh Vencejo",
      "name_2_prof":"Estudiante Técnico en Farmacia",
      "name_2_text":"Liceo me ha ayudado a enfrentarme con más seguridad a mis prácticas, y luego a primer trabajo. Los contenidos son perfectos para quién busca sentirse más seguro en el mostrador. ",
      "name_3":"Lucía Fernandez Gallego",
      "name_3_prof":"Estudiante Farmacia",
      "name_3_text":"Siempre me costó relacionar los principios activos a las marcas. Por eso me ha encantado la app de La Infiltrada. La recomiento 100% "
    },
    "pharmacy_plan":{
      "get":"Obtén el plan que se adapte a tu farmacia",
      "option_a_1":"Para equipos de hasta 4 integrantes",
      "option_a_2":"Espacio con logo y colores de farmacia",
      "option_b_1":"Para equipos de hasta 10 integrantes",
      "option_b_2":"Espacio con logo y colores de farmacia",
      "option_b_3":"Sube tus propias formaciones",
      "option_c_1":"Para equipos de hasta 10 integrantes",
      "option_c_2":"Espacio con logo y colores de farmacia",
      "option_c_3":"Sube tus propias formaciones",
      "option_c_4":"Informes de seguimiento",
      "option_c_5":"Itinerarios formativos personalizados para cada usuario"


    },
    "infiltrada":{
      "title":"La infiltrada",
      "download":"DESCÁRGALA GRATIS",
      "ask_you":"¿Sabes identificar qué principios activos contienen las marcas?",
      "download_free":"Descarga GRATIS",
      "download_text":" la app que te ayuda a aprender de forma divertida qué marcas corresponden a cada principio activo.",
      "android":"DESCARGAR ANDROID",
      "ios":"DESCARGAR IOS"
    }
  },
  "error": {
    "account": {
      "emailNotFound": "No existe una cuenta de usuario con el email proporcionado",
      "passwordIncorrect": "La contraseña proporcionada es incorrecta"
    },
    "server": {
      "generic": "Ha ocurrido un error inesperado, inténtelo más tarde"
    },
    "course": {
      "coursesNotFound": "No hay resultados para esta búsqueda"
    }
  },
  "error_modal": {
    "title": "Oops!",
    "sub_title": "Algo no ha ido del todo bien",
    "msg": "",
    "description": "Para más información ponte en contacto a través de nuestro chat o escríbenos a info@liceodefarmacia.com",
    "info": "",
    "btnText": "VOLVER A LICEO",
    "btn_demo_already_used": "VER PLANES",
    "demo_already_used": "Ya has disfrutado de una prueba gratis",
    "msg_demo_already_used": "Mejora y sigue aprendiendo con nuestros cursos, por suscripción"
  },
  "purchase_made": {
    "greetings": "¡Enhorabuena!",
    "title_1": "Ya tienes acceso a tu",
    "title_2": "Empieza ya con uno de",
    "title_2-5": "nuestros cursos recomendados",
    "btn": "IR AL AULA"
  },
  "faqs": {
    "title": "Preguntas frecuentes"
  },
  "my_itineraries": {
    "return_btn": "Volver a campus",
    "title_1": "Gestor de itinerarios",
    "create_itinerary_btn": "CREAR ITINERARIO",
    "avaible_text": "Curso disponible el",
    "edit_btn": "Editar",
    "duplicate_btn": "Duplicar",
    "delete_btn": "Eliminar",
    "error_popup": "¡Lo sentimos! Ha habido un error",
    "success_delete_popup": "¡Itinerario eliminado con éxito!",
    "success_duplicate_popup": "¡Itinerario duplicado con éxito!",
    "success_duplicate_template": "¡Plantilla duplicada con éxito!",
    "success_save_popup": "¡Itinerario guardado con éxito!",
    "success_publish_popup": "¡Itinerario publicado con éxito!",
    "success_save_as_draf_popup": "¡Guardado como borrador con éxito!",
    "success_changes_publish_popup": "¡Cambios publicados con éxito!",
    "success_traslate_popup": "Trasladado a borrador con éxito!",
    "success_save_changes_popup": "¡Cambios guardados con éxito!",
    "see_courses_btn": "Ver cursos",
    "close_courses_btn": "Cerrar",
    "confirm_title_itinerary": "¿Quieres eliminar el itinerario?",
    "confirm_description_itinerary": "Si eliminas el itinerario, este se borrará definitivamente y no se podrá recuperar. Además, los usuarios perderán el acceso. Escribe la palabra \"Borrar\" para confirmar la eliminación del itinerario.",
    "confirm_btn_confirm_itinerary": "ELIMINAR ITINERARIO",
    "confirm_title_draft": "¿Quieres eliminar el borrador?",
    "confirm_description_draft": "Si eliminas el borrador del itinerario, este se eliminará definitivamente y no se podrá recuperar. Escribe la palabra \"Borrar\" para confirmar la eliminación del borrador.",
    "confirm_btn_confirm_draft": "ELIMINAR BORRADOR",
    "confirm_btn_cancel": "CANCELAR",
    "confirm_placeholder": "borrar",
    "empty_all_list_title": "¡Aún no tienes itinerarios!",
    "empty_all_list": "Crea itinerarios personalizados para agrupar cursos, ordenarlos según tu preferencia e incluso determinar las fechas en las que estarán disponibles. Además, puedes duplicar plantillas existentes y utilizarlas como punto de partida para crear tus propios itinerarios.",
    "empty_published_list_title": "¡Aún no tienes itinerarios publicados!",
    "empty_published_list": "Los itinerarios publicados son visibles y accesibles para todos los usuarios de tu farmacia. Una vez publicados, los usuarios serán matriculados automáticamente en los cursos que integran dicho itinerario.",
    "empty_draft_list_title": "¡Aún no tienes borradores!",
    "empty_draft_list": "Los itinerarios en borrador no son visibles para los usuarios de tu farmacia hasta su publicación. Puedes crear y editar libremente; una vez finalizados, publícalos para permitir el acceso al resto de usuarios.",
    "empty_template_list_title": "¡Aún no tienes plantillas!",
    "empty_template_list": "Liceo de Farmacia te va a proporcionar unas plantillas para que te sirvan de referencia a la hora de crear itinerarios. ¡Pronto las tendrás disponibles!",
    "error_load_itineraries_title": "Ha ocurrido un error.",
    "error_load_itineraries_desc": "Por favor, inténtalo más tarde. Si persiste, contáctanos.",
    "last_date_update": "Última modificación",
    "last_date_update_mobile": "Modificado",
    "see_more_btn": "Ver cursos",
    "see_less_btn": "Cerrar"
  },
  "config_itinerary": {
    "step3": {
      "title": "ORDENAR CURSOS",
      "date_block_modal": {
        "title": "SELECCIONA LA FECHA",
        "description": "Establece la fecha en la que quieras que se desbloquee el curso.",
        "avaible_text": "Curso disponible el ",
        "btn_save": "GUARDAR",
        "btn_cancel": "CANCELAR"
      },
      "new-itinerary":{
        "title": "Itinerario"
      },
      "back_btn": "ATRÁS",
      "next_btn": "SIGUIENTE",
      "save_btn": "GUARDAR COMO BORRADOR",
      "traslate_btn": "TRASLADAR A BORRADORES",
      "description": "Arrastra para ordenar los cursos. También puedes bloquear los cursos por fecha. Estarán disponibles para el alumno en la fecha que selecciones."
    },
    "step4": {
      "back_btn": "ATRÁS",
      "next_btn": "PUBLICAR",
      "title": "VISTA PREVIA",
      "save_btn": "GUARDAR COMO BORRADOR",
      "traslate_btn": "TRASLADAR A BORRADORES",
      "description": "¡Así se verá tu itinerario!",
      "confirm_modal":{
        "title":"¿Quieres publicar el itinerario?", 
        "description_0":"Vas a publicar el itinerario ",
        "description_1":" y van a pasar a tener acceso los usuarios de ",
        "confirm_btn_text":"ACEPTAR",
        "cancel_btn_text":"CANCELAR"
      }
    },
    "title_1": {
      "create": "Crear itinerario",
      "edit": "Editar itinerario"
    },
    "step_pharmacies":{
      "title": "GESTIONAR FARMACIAS",
      "title_minus": "Gestionar farmacias",
      "desc": "Selecciona a las farmacias de tu grupo que vayan a tener acceso al itinerario"
    },
    "step_users":{
      "title": "GESTIONAR USUARIOS",
      "title_minus": "Gestionar usuarios",
      "desc": "Selecciona a los usuarios de tu farmacia que vayan a tener acceso al itinerario"
    },
    "confirm_modal_save_progress":{
      "title":"¿Quieres guardar el progreso?", 
      "description":"Si vuelves a tus itinerarios perderás progreso. Guarda como borrador el itinerario si quieres mantenerlo.",
      "confirm_btn_text":"GUARDAR COMO BORRADOR",
      "cancel_btn_text":"VOLVER SIN GUARDAR"
    },
    "confirm_modal_traslate_to_draft":{
      "title":"¿Quieres trasladar a borradores?", 
      "description":"Si mandas este itinerario a borradores podrás seguir editándolo, pero los usuarios dejarán de tener acceso a él. Escribe la palabra \"Confirmar\" para confirmar la conversión del itinerario publicado a borrador.",
      "confirm_btn_text":"TRASLADAR A BORRADORES",
      "cancel_btn_text":"CANCELAR"
    },
    "saved_success_popup":{
      "title":"!Genial¡", 
      "description":"¡El itinerario ha sido guardado como borrador!",
      "confirm_btn_text":"ACEPTAR"
    },
    "publish_success_popup":{
      "title":"¡Enhorabuena!", 
      "description":"¡El itinerario ha sido publicado con éxito!",
      "confirm_btn_text":"ACEPTAR"
    },
    "itinerary_name_already_used":{
      "title":"¡Ups!", 
      "description":"Ya existe un itinerario con ese nombre",
      "confirm_btn_text":"Cambiar nombre"
    },
    "resume-users-title": "USUARIOS CON ACCESO",
    "resume-users-desc": "Estos son los que van a tener acceso al itinerario.",
    "resume-pharmacies-title": "FARMACIAS CON ACCESO",
    "resume-pharmacies-desc": "Estas son las que van a tener acceso al itinerario.",
    "selected-users": "Usuarios seleccionados",
    "selected-pharmacies": "Farmacias seleccionadas",
    "selected-courses": "Cursos seleccionados",
    "publish-btn": "PUBLICAR",
    "left-wrap-title": "NOMBRE",
    "left-wrap-desc-title": "DESCRIPCIÓN",
    "left-wrap-desc-description": "Añade una breve anotación sobre el itinerario.",
    "right-wrap-title": "TIPO",
    "right-wrap-description": "Selecciona el tipo de itinerario:",
    "next_btn": "SIGUIENTE",
    "back_btn": "ATRÁS",
    "save_btn": "GUARDAR COMO BORRADOR",
    "traslate-btn": "TRASLADAR A BORRADORES",
    "save-changes-btn": "GUARDAR CAMBIOS",
    "save-as-draft-btn": "GUARDAR COMO BORRADOR",
    "publish-changes-btn": "PUBLICAR CAMBIOS",
    "return_btn": "Volver",
    "step2": {
      "title": "LISTADO DE CURSOS",
      "new-itinerary":{
        "title": "Itinerario"
      },
      "back_btn": "ATRÁS",
      "search_resutl": "Resultados de la búsqueda ",
      "filter_title": "Filtrados por:",
      "next_btn": "SIGUIENTE",
      "save_btn": "GUARDAR COMO BORRADOR",
      "traslate_btn": "TRASLADAR A BORRADORES",
      "description": "Selecciona los cursos que quieres que formen parte del itinerario:"
    },
    "step_details":{
      "title": "DETALLES DEL ITINERARIO",
      "desc": "Crea un itinerario formativo personalizado."
    }
  },
  "pharmacy_dashboard":{
    "back": "Volver al grupo",
    "progress": "Progreso de tu farmacia:",
    "result": "Resultado promedio de la farmacia",
    "last_update": "Última actualización",
    "your_team": "Tu equipo",
    "clean_filters": "Limpiar filtros",
    "user_progress": "Progreso del usuario",
    "last_access": "último acceso",
    "ranking": "En el ranking",
    "search_result": "Resultados de búsqueda:",
    "courses": "cursos",
    "sort_by": "Ordenar por",
    "filters": "Filtros",
    "switchBtnCourses": "Cursos",
    "switchBtnUsers": "Usuarios",
    "itinerary_selec_default_value": "Itinerarios",
    "course_selec_default_value": "Cursos",
    "download_btn": "Descargar estadísticas",
    "download_btn_mobile": "Descargar",
  },
  "group_dashboard":{
    "group_progress": "El progreso de tu grupo:",
    "result": "Resultado promedio del grupo",
    "result_pharma": "Resultados promedio por farmacia:",
    "last_update": "Última actualización",
    "users":"Usuarios",
    "pharma":"Farmacias",
    "pharmacies":"farmacias",
    "search_result": "Resultados de búsqueda:",
    "clean_filters": "Limpiar filtros",
    "sort_by": "Ordenar por",
    "holder": "Titular",
    "level": "Nivel",
    "dedication":"Dedicación",
    "complete":"Completados",
    "progress":"Progreso",
    "switchBtnCourses": "Cursos",
    "switchBtnPharmacies": "Farmacias",
  },
  "change_password":{
    "error_text": "Las contraseñas deben coincidir",
    "pass_placeholder": "Nueva contraseña",
    "pass_confirm_placeholder": "Confirmar nueva contraseña",
    "btn": "CAMBIAR CONTRASEÑA"
  },
  "course_progress":{
    "registered": "Matriculado",
    "date_avaible_text": "Curso disponible el",
    "certificate_btn": "CERTIFICADO",
    "more_info_btn": "Más información",
    "content_title": "Contenido",
    "register_btn":"MATRICULARME",
    "what_consist": "¿En qué consiste?",
    "time_spent": "Tiempo empleado",
    "no_courses": "Aún no tienes cursos asignados",
    "date_block": "Curso disponible el",
    "complete":"completado",
    "progress_block": "Para hacer este curso debes completar los anteriores",
    "disable": "No incluido en tu licencia demo",
    "certificate_modal":{
      "show_progress_linkedin": "Muestra tu progreso en Linkedin",
      "share_on_linkedin": "COMPARTIR EN LINKEDIN",
      "add_to_profile": "AÑADIR A MI PERFIL",
      "download_btn": "DESCARGAR CERTIFICADO",
      "return_btn": "volver"
    }
  },
  "forgot_password":{
    "recover_btn": "RECUPERAR CONTRASEÑA",
    "recover-text":"Recuperar contraseña",
    "text_1":"Introduce tu email,",
    "text_2":"te enviaremos una contraseña nueva.",
    "look_email":"Revisa tu email",
    "text_3":"Te hemos enviado ",
    "text_3_1":"una contraseña nueva ",
    "text_3_2":"para confirmar tu identidad.",
    "text_3_3":" Copia y pega",
    "text_3_4":"la contraseña",
    "change_pass":"Cambiar contraseña",
    "text_4":"Para proteger tu cuenta con",
    "text_4_1":" una contraseña segura",
    "text_4_2":" debe cumplir estos requisitos",
    "minimun":"- tener mínimo",
    "characters":" 8 caracteres",
    "at_less":"- contener al menos",
    "one_number":" un número y una letra",
    "ready":"¡Listo!",
    "password_reseted":"Contraseña reestablecida",
    "you":"Tu",
    "pass_saved":" contraseña se ha guardado correctamente.",
    "come_back":"Puedes volver a entrar ",
    "in_site":"en liceo.",
    "go_to":"Ir a Liceo",
    "btn":"CAMBIAR CONTRASEÑA"


    
  },
  "input_multiselect":{
    "reset": "Restablecer",
    "save": "GUARDAR"
  },
  "newsletter":{
    "title": "¿Quieres estar al día de todo nuestro contenido?",
    "info": "Suscríbete a la Newsletter. Forma parte de Liceo y mantente actualizad@ con información sobre nuevos cursos y noticias.",
    "ready":"¡Todo listo!",
    "thanks_1":"Muchas gracias por formar parte de Liceo.",
    "thanks_2":"¡Te mantendremos informad@!",
    "legal":"Acepto la política de privacidad y los Términos y Condiciones"
  },
  "static_itinerary":{
    "avaible_date":"Curso disponible el"
  },
  "already_have_active_plan":{
    "title": "Ya tienes un plan activo:",
    "see_sub": "VER MI SUSCRIPCIÓN",
    "start_course": "Empieza ya con uno de nuestros cursos recomendados",
    "courses_included_1": "Estos son algunos de los cursos",
    "courses_included_2": "que incluye tu suscripción"
  },
  "features_table":{
    "single": "Individual",
    "pharmacy": "Farmacia",
    "student": "Estudiante",
    "professional": "Profesional",
    "users": "Usuarios",
    "info_1": "Espacio privado propio",
    "info_2": "Catálogo con más de 150 cursos creados por profesionales en activo",
    "info_3": "Nuevos cursos todos los meses",
    "info_4": "Estadísticas",
    "info_5": "Informes de seguimiento de equipo",
    "info_6": "Aplicación Liceo Play (iOs y Android) y prueba de conocimiento",
    "info_7": "Aplicación Protocolos de Consejo (iOs y Android)",
    "info_8": "Subida de contenido propio",
    "info_9": "Herramienta para crear tus itinerarios propios",
    "info_10": "Plantillas de itinerarios preestablecidos",
    "info_11": "Espacio personalizado con tu marca",
    "info_12": "Seguimiento personalizado",
    "acces_to_all": "Acceso a todos los cursos",
    "new_content": "Nuevos cursos cada mes",
    "create_by_professionals": "Formación creada por profesionales",
    "update_content": "Contenido actualizado",
    "formation": "Formación teórico y práctica",
    "scenes": "Escenas de mostrador",
    "personaliced_space": "Espacio personalizado con logo",
    "upload_own": "Sube tus propias formaciones",
    "reports": "Informes de seguimiento",
    "personaliced_itineraries": "Itinerarios formativos personalizados por usuarios"
  },
  "certificates":{
    "info":"Información sobre el curso",
    "inproved": ">Mejora tus habilidades como profesional de farmacia con el plan de formación continua de Liceo de Farmacia.",
    "catalogue":"Con nuestro catálogo de cursos tendrás acceso a una amplia variedad de contenidos diseñados por los mejores expertos del sector.",
    "catalogue_btn": "CATÁLOGO DE CURSOS"
  },
  "infiltrada":{
    "title":"Descubre la infiltrada:",
    "parraf_1":"¿Qué tal se te dan los principios activos?",
    "parraf_2":"Juega y refuerza tus conocimientos de forma divertida",
    "enable_platforms":"Disponible en Google Play y app Store",
    "sub_title_1":"Juega y aprende",
    "sub_parraf_1":"Memoriza y repasa principios activos con la infiltrada",
    "sub_title_2":"Selecciona y valida",
    "sub_parraf_2":"Encuentra la infiltrada, verifica tu respuesta y gana EXP",
    "sub_title_3":"Mide tu progreso",
    "sub_parraf_3":"A través de tus logros y posición en el Ranking de la infiltrada"
  },
  "protocolo":{
    "title":"Conoce Protocolos de consejo:",
    "title_end":"Protocolos de consejo",
    "parraf_1":"Realiza fácilmente los protocolos de consejo con tu equipo de farmacia",
    "enable_platforms":"Disponible en",
    "sub_title_1":"Trabaja con tu",
    "sub_title_bold_1":"equipo de farmacia",
    "sub_parraf_1":"Para mejorar el servicio de venta y la rentabilidad de la farmacia",
    "sub_title_2":"Generación de",
    "sub_title_bold_2":"propuestas en equipo",
    "sub_parraf_2":"Proponed productos, preguntas y consejos relacionados con la necesidad",
    "sub_title_3":"Revisión",
    "sub_title_bold_3":"y votación",
    "sub_parraf_3":"Presenta la mejor propuesta, consigue puntos y asciende en el Ranking",
    "sub_title_4":"Usa tus",
    "sub_title_bold_4":"protocolos",
    "sub_parraf_4":"Una vez completados los tendrás siempre disponibles en tu smartphone",
    "sub_title_5":"Crea un",
    "sub_title_bold_5":"protocolo",
    "sub_parraf_5":"Determina una necesidad sobre la que queráis trabajar"
    
  },
  "infarma":{
    "title_1": 'Impulsando la nueva farmacia',
    "title_2": 'Visítanos en',
    "title_3": 'stand 5C32',
    "header_apps": "Método Liceo"
  },
  "preguntas_frecuentes":{
    "title": "Preguntas frecuentes",
    "question_1": "¿En qué consiste el servicio? / ¿Qué es Liceo?",
    "answer_1": "Con Liceo de Farmacia tendrás acceso a un conjunto de herramientas que te permitirán potenciar tus habilidades o las de tu equipo de farmacia, a través de la formación continua creada por profesionales independientes del sector. <br><br>En nuestro campus contarás con más de 150 cursos, basados en el método Liceo, que se centran en mejorar la capacidad de venta a través del consejo farmacéutico.<br><br>Además contarás con varias apps que buscarán que todo sea dinámico, divertido y efectivo. Por ejemplo, nuestra app Protocolos de Consejo se centra en acomodar lo aprendido en argumentarios que usar día a día y nuestra App Liceo Play permite validar conocimientos adquiridos resolviendo retos divertidos de tipo trivia.",
    "question_2": "¿Qué precio tienen? ¿Son gratuitos?",
    "answer_2": "El precio de Liceo de Farmacia varía según si eres un estudiante, profesional o titular. En todos los casos puedes probar 5 días gratis. Puedes ver los precios en <a target='blank' href='https://liceodefarmacia.com/planes'>este link</a>.",
    "question_3": "¿Puedo cancelar cuando quiera?",
    "answer_3": "Sí, puedes cancelar cuando quieras. Liceo de Farmacia funciona bajo suscripción. Se realizará un pago mensual o anual, según tu elección. Si suspendes tu licencia antes de 7 días del próximo pago ya no se te cobrará el siguiente recibo.",
    "question_4": "¿En qué consiste la prueba gratis?",
    "answer_4": "En la prueba gratis tendrás acceso a todos los servicios durante 5 días. Por ello es importante que hagas la prueba gratis con la licencia que te corresponde (estudiante, profesional o titular), así podrás probar esos 5 días todas las funcionalidades que te interesan.",
    "question_5": "¿Tenéis cursos subvencionados con los créditos de la tripartita ?",
    "answer_5": "Sí, si tienes una farmacia, es posible que tus empleados realicen los cursos de Liceo de manera bonificada mediante créditos de Fundae / Tripartita. Nosotros nos encargamos de realizar toda la gestión burocrática de manera que sólo tengas que reenviar esa documentación que nosotros generamos a tu gestoría laboral, y puedas recuperar así el 100% del coste de los cursos mediante bonificación en los seguros sociales. Si quieres saber más, escríbenos tu número de teléfono a info@liceodefarmacia.com y contactaremos para contártelo al detalle",
    "question_6": "¿Qué diferencia hay entre el plan profesional y el estudiante?",
    "answer_6": "El plan estudiante tiene acceso a 20 cursos centrados en las necesidades de esta etapa. El objetivo de los cursos está enfocado en conseguir un primer trabajo en farmacia, adquirir conocimientos básicos para dicha experiencia. El plan profesional incluye el catálogo completo con más de 150 cursos creados por profesionales en activo y nuevos cursos cada mes orientados a potenciar la atención en el mostrador de la farmacia.",
    "question_7": "¿Qué plan debería coger?",
    "answer_7": "En Liceo de Farmacia puedes escoger entre los siguientes planes:<br><br>Si eres un estudiante de farmacia, o ya has terminado tus estudios pero estás buscando tu primer trabajo en una farmacia tu plan ideal es: <b>Plan estudiantes de farmacia</b><br><br>Si eres un profesional de farmacia y quieres potenciar tus habilidades en el mostrador, tu plan ideal es: <b>Plan profesional de farmacia</b><br><br>Si eres un titular o responsable de farmacia, y quieres que tu equipo esté formado y potenciar así las ventas de tu botica, puedes elegir entre los siguientes planes: <br><br><b>Farmacia M</b> (hasta 4 usuarios)<br><br><b>Farmacia L</b> (hasta 10 usuarios)<br><br><b>Farmacia XL</b> (hasta 20 usuarios)<br><br><br><br>Todos los planes están disponibles en suscripción anual o mensual. La suscripción mensual se paga mes a mes, mientras que la anual se abona en un único pago <b>ahorrando un 20%.</b>",
    "question_8": "¿Podéis llamarme para resolver alguna duda?",
    "answer_8": "¡Claro! Escríbenos tu número de teléfono a info@liceodefarmacia.com y te llamaremos en menos de 24 horas laborables.",
    "question_9": "¿Los cursos son acreditados? ¿Otorgan créditos ECTS?",
    "answer_9": "No, nuestros créditos no cuentan con la homologación de ninguna entidad universitaria, y por tanto no otorgan créditos ECTS. <br><br>Además, el enfoque que buscamos es que obtengas el máximo aprendizaje práctico para aplicar en tu farmacia en el menor tiempo posible. Si podemos formarte en una temática en 30 minutos, ¿por qué hacer que emplees más tiempo? Mejor que puedas aprender algo rápido y aplicarlo en el mostrador para afianzarlo. Los cursos homologados, sin embargo, necesitan acreditar 10 horas de dedicación del estudiante por cada crédito que otorgan, y por eso son densos, teóricos e incluyen muchísimos conceptos que realmente no son los que usas en el día a día de tu farmacia. <br><br>No obstante, no siendo acreditados, la formación de Liceo es realizada por farmacéuticos en activo, que viven el mismo día a día que tú, y nuestro estándar de calidad es reconocido por farmacias, grupos y laboratorios.",
    "question_10": "¿Un auxiliar o técnico de farmacia puede hacer los cursos?",
    "answer_10": "Claro, cualquier miembro de la farmacia puede hacer lo cursos, pues están centrados en la parte práctica del día a día que sucede cada día en la farmacia, donde todos, auxiliares, técnicos y facultativos, están implicados.",
    "question_11": "¿Qué cursos tenéis disponibles?",
    "answer_11": "Tenemos un catálogo de más de 150 cursos, organizados en categorías como gestión, infantil, cosmética, nutrición, etc.. Puedes verlos en <a target='blank' href='https://liceodefarmacia.com/cursos'>este enlace</a>",
    // "question_12": "Me gustaría saber si tienes cursos de X.",
    // "answer_12": "",
    // "question_13": "Me he registrado y no puedo acceder / No consigo entrar con mi usuario",
    // "answer_13": "",
    "question_14": "Soy estudiante y me gustaría conseguir mi primer trabajo en una farmacia. ¿Esto me ayudaría?",
    "answer_14": "El motivo por el que se creó Liceo de Farmacia es para formar a profesionales top en las tareas clave que se realizan en una oficina de farmacia a diario. De hecho, todo el contenido es generado por profesionales excelentes en ese área de conocimiento que les encargamos que saben bien qué comportamientos y habilidades son los que mejor resultado dan en el día a día.<br><br>Por eso, si eres estudiante o acabas de terminar tus estudios, y pretendes trabajar en una farmacia, llevar todas esas habilidades bien desarrolladas será muy valioso para el titular de farmacia, y eso te distinguirá respecto al resto de candidatos."
  },
  "landing_courses_expert":{
    "title": "Curso Liceo Expert",
    "price_text": "Precio:",
    "experts_title_1": "Curso desarrollado por especialistas",
    "experts_title_2": "All Stars del sector",
    "trust_title": "Confían en nosotros",
    "discount_1": "descuento con",
    "discount_2": "venta anticipada",
    "buy_button": "COMPRAR",
    "contact_button": "CONTACTAR",
    "salud_capilar":{
      "name": "Salud capilar en la farmacia" ,
      "subtitle": "Desarrollado por especialistas All Stars del sector",
      "info_1": "Modalidad online",
      "info_2": "Duración del curso: 20 horas",
      "info_4": "Certificado Liceo Expert: Salud capilar en la farmacia",
      "description": {
        "title":"Capilar: La Nueva Tendencia en las Farmacias.",
        "subtitle":"En 2022, el segmento del cuidado del cabello creció un 8%, destacando entre los demás.",
        "desc":"Este curso une la metodología y herramientas de Liceo con el conocimiento de los mejores expertos en capilar y la práctica de los mostradores más punteros del país. Con casos prácticos, rutinas, ejemplos y experiencias de los mismos, que abarca todas las patologías y posibles consultas relacionadas con el cuidado capilar."
      },
      "features": {
        "title": "Obtén tu plaza y accede al Curso Liceo Expert",
        "card_1": {
          "title": "Aprende a tu ritmo",
          "desc": "Con píldoras de video cortas y material teórico para ver en tu móvil u ordenador."
        },
        "card_2": {
          "title": "Creado por referentes del sector",
          "desc": "Profesionales reconocidos del sector con amplia experiencia en mostrador."
        },
        "card_3": {
          "title": "Formación enfocada en la práctica",
          "desc": "Contenido creado para que sea útil y aplicable en el día a día de la farmacia"
        },
      },
      "itinerary":{
        "title": "¿Qué vas a aprender?",
        "point_1":{
          "title": "1. Gestión categoría capilar",
          "desc": "- Javier Baquero"
        },
        "point_2":{
          "title": "2. Anatomía y fisiología capilar",
          "desc": "- Eduardo Senante"
        },
        "point_3":{
          "title": "3. Patologías del cuero cabelludo ",
          "desc": "- Eduardo Senante"
        },
        "point_4":{
          "title": "4. Caída Capilar",
          "desc": "- Rocío del Barrio"
        },
        "point_5":{
          "title": "5. Piojos",
          "desc": "- Manuel Ojeda"
        },
        "point_6":{
          "title": "6. Daño Capilar",
          "desc": "- Helena Rodero"
        },
        "point_7":{
          "title": "7. Cosmética capilar",
          "desc": "- Helena Rodero"
        },
      },
      "advices":{
        "title": "Este curso es para ti si...",
        "advice_1": "Te apasiona el mundo capilar y quieres especializarte en esta categoría",
        "advice_2": "Quieres mejorar la rentabilidad de tu farmacia mediante protocolos de venta por consejo que satisfagan a tus usuarios",
        "advice_3": "Buscas potenciar tu servicio en el mostrador mediante la atención de patologías relacionadas con la piel",
        "advice_4": "Te gustaría ayudar a los clientes a mantener un pelo cuidado y saludable",
      },
      "capabilities":{
        "title": "Qué serás capaz de hacer una vez realices y finalices este curso:",
        "capability_1": "Llevar a la práctica y bajar al mostrador todo lo aprendido, gracias a que toda la metodología es muy práctica.", 
        "capability_2": "Podrás proporcionar asesoramiento experto a los clientes sobre el cuidado del cabello, la selección de productos adecuados para su tipo de cabello y las preocupaciones específicas que puedan tener.", 
        "capability_3": "Con un conocimiento más profundo de los ingredientes y las tecnologías utilizadas en productos capilares, podrás recomendar los productos más efectivos para abordar las necesidades individuales de tus clientes.", 
        "capability_4": "Podrás educar a los clientes sobre prácticas de cuidado del cabello saludables, como la importancia de una dieta equilibrada, el uso adecuado de herramientas de peinado, las técnicas de lavado, peinado y desenredado y la protección contra el daño causado por el sol y el calor.", 
        "capability_5": "Serás capaz de realizar un seguimiento de los resultados de los productos recomendados y ajustar los tratamientos según sea necesario.", 
        "capability_6": "Estarás preparado para manejar situaciones especiales, como patologías relacionadas con el cuero cabelludo y daños en la fibra capilar, ofreciendo un consejo experto sobre cómo minimizar el riesgo de problemas futuros y su prevención."
      },
      "certificate":{
        "title_1": "Consigue tu certificado Liceo Expert:",
        "title_2": "Salud capilar en la farmacia"
      },
      "contact":{
        "title": "¿Ya eres cliente de Liceo? ",
        "desc": "<p>Contáctanos para conocer tu descuento en el <b>Curso Liceo Expert: Salud capilar en la farmacia</b></p>"
      },
      "banner":{
        "name": "Salud capilar en la farmacia",
      }
    }
  }
}